/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Box } from '@material-ui/core';
import React from 'react';
import styles from '../styles/FormFieldDivider.module.scss';

export class FormFieldDivider extends React.Component {
    public render() {
        return <Box width="100%" className={styles.divider}></Box>;
    }
}
