import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { FormField } from '../../components/FormField';
import { FormFieldDivider } from '../../components/FormFieldDivider';
import { TopBar } from '../../components/TopBar';
import styles from '../../styles/LoginIndex.module.scss';
import PageBase from '../PageBase';
import { Utils } from '../../models/Utils';
import { Redirect } from 'react-router-dom';

type IData = {
    email: string;
    password: string;
    error: string;
    success: boolean;
    isLoading: boolean;
    redirect: string;
};

export default class Login extends PageBase {
    constructor(props: any) {
        super(props);
        this.OnInputChange = this.OnInputChange.bind(this);
        this.OnSubmit = this.OnSubmit.bind(this);
    }

    state: IData = {
        email: '',
        error: '',
        success: false,
        password: '',
        isLoading: false,
        redirect: this._authService.IsAuthenticated ? '/event/manage' : '',
    };

    private async OnSubmit(e: any) {
        e.preventDefault();
        if (!this.ValidateForm()) {
            return;
        }
        this.setState({ isLoading: true });

        try {
            const loginResult = await this._authService.Login(this.state.email, this.state.password);
            if (!loginResult || !loginResult.Success) {
                this.setState({
                    error: loginResult ? loginResult.Error : 'Invalid credentials',
                });

                return;
            }
            window.location.href = '/event/manage';
        } catch (error) {
            console.log(error);
            this.setState({ error: `${error.statusText}, Invalid credentials` });
            this.setState({ isLoading: false });
        }
    }
    private OnInputChange(e: any) {
        this.setState({ error: '' });
        switch (e.target.name) {
            case 'email':
                this.setState({ email: e.target.value });
                break;
            case 'password':
                this.setState({ password: e.target.value });
                break;
            default:
                break;
        }
    }

    private ValidateForm(): boolean {
        const { email, password }: IData = this.state;

        if (!email || !Utils.IsValidEmail(email)) {
            this.setState({ error: 'Invalid email address' });
            return false;
        }
        if (!password) {
            this.setState({ error: 'Provide your password' });
            return false;
        }

        this.setState({ error: '' });

        return true;
    }
    public render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }
        return (
            <Box mx="auto" maxWidth={800} flexDirection="column">
                <TopBar />
                <Box alignItems="center" my={2}>
                    <Box textAlign="center">
                        <Typography variant="h5" className={styles.title}>
                            Login
                        </Typography>
                    </Box>
                </Box>
                {this.state.error && (
                    <Box py={0.5} px={1} mb={1} className={styles.errorBox} textAlign="center">
                        <Typography variant="subtitle1">{this.state.error} </Typography>
                    </Box>
                )}
                <form onSubmit={this.OnSubmit}>
                    <Box px={2} className={styles.formBody}>
                        <FormField
                            onChange={this.OnInputChange}
                            focused={true}
                            isRequired={true}
                            id="email"
                            inputType={'text'}
                            label="Email"
                        />
                        <FormFieldDivider />
                        <FormField
                            onChange={this.OnInputChange}
                            isRequired={true}
                            id="password"
                            inputType="password"
                            label="Password"
                        />
                        <FormFieldDivider />
                    </Box>

                    <Box
                        width="100%"
                        justifyContent={{ xs: 'flex-center', sm: 'flex-end' }}
                        justifyItems="flex-end"
                        mt={2}
                        display="flex"
                    >
                        <Box
                            width={{ xs: '100%', sm: 250 }}
                            height={50}
                            className={styles.login}
                            alignItems="center"
                            display="flex"
                        >
                            <Button disabled={this.state.isLoading} fullWidth={true} type="submit">
                                Login
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        );
    }
}
