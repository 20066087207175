/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Router } from 'react-router-dom';
import history from './services/history/History';
import Routes from './routes/Routes';

class App extends React.Component {
    render() {
        return (
            <Router history={history}>
                <Routes />
            </Router>
        );
    }
}

export default App;
