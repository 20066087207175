import React from 'react';
import { ApiConfig } from '../services/ApiConfig';
import { AuthService } from '../services/auth/AuthService';

export default class PageBase extends React.Component {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(props: any) {
        super(props);
        this._authService = new AuthService();
        this._apiServiceConfig = ApiConfig.ServiceConfiguration;
    }
    protected _authService: AuthService;
    protected _apiServiceConfig: Rpm.Api.Client.Models.ServiceConfiguration;
}
