/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-useless-escape */
import _ from 'underscore';
export class Utils {
    static StringIsNullOrEmpty(str: string): boolean {
        return _.isNull(str) || _.isUndefined(str) || str.length === 0;
    }
    static IsNull(value: any): boolean {
        return _.isNull(value) || _.isUndefined(value);
    }

    static EnsureArgument(arg: any, argName: string): void {
        if (typeof arg == 'string' && Utils.StringIsNullOrEmpty(arg)) {
            throw new Error(argName + ' string cannot be null or empty.');
        }

        if (arg == null) {
            throw new Error(argName + ' cannot be null.');
        }

        if (!arg) {
            throw new Error(argName + ' cannot be undefined.');
        }
    }

    static IsValidEmail(email: string): boolean {
        return /^[a-z0-9_\.\+-]*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    }
}
