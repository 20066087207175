import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Manage from '../pages/event/Manage';
import Home from '../pages/home/Home';
import Login from '../pages/login/Login';

class Routes extends React.Component {
    render() {
        return (
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/login" component={Login} />
                <Route path="/event/manage" component={Manage} />
            </Switch>
        );
    }
}

export default Routes;
