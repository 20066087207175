/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { TopBar } from '../../components/TopBar';
import { LANGUAGES } from '../../config/languages';
import styles from '../../styles/Manage.module.scss';
import PageBase from '../PageBase';
import _ from 'underscore';
import { FormField } from '../../components/FormField';
import { FormFieldDivider } from '../../components/FormFieldDivider';
import { CampainService } from '../../services/campaign/CampaignService';
import { Redirect } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
type IState = {
    eventStats: { [key: string]: number };
    statsKeys: string[];
    success: boolean;
    messageBody: string;
    messageTitle: string;
    selectedLanguage: string | undefined;
    isLoading: boolean;
    error: string | undefined;
    redirect: string;
};

export default class Manage extends PageBase {
    constructor(props: any) {
        super(props);
        this.OnSubmit = this.OnSubmit.bind(this);
        this.OnInputChange = this.OnInputChange.bind(this);
        this.Logout = this.Logout.bind(this);
    }

    state: IState = {
        eventStats: {},
        statsKeys: [],
        success: false,
        messageBody: '',
        messageTitle: '',
        selectedLanguage: undefined,
        isLoading: false,
        error: undefined,
        redirect: !this._authService.IsAuthenticated ? '/login' : '',
    };

    async componentDidMount() {
        if (this._authService.IsAuthenticated) {
            const token = await this._authService.Token;
            this._apiServiceConfig.Token = token ? token.Token : '';
            const eventAttendeesService = new Rpm.Api.Client.Service.ViewEventAttendeeService(this._apiServiceConfig);
            eventAttendeesService
                .Statistic(
                    LANGUAGES.map((l) => {
                        return l.code;
                    }),
                )
                .then((stats) => {
                    if (stats) {
                        this.setState({ eventStats: stats, statsKeys: _.keys(stats) });
                    }
                });
        }
    }

    private async OnSubmit(e: any) {
        e.preventDefault();
        if (!this.ValidateForm()) return;
        this.setState({ isLoading: true });
        this.setState({ error: '' });

        const campaignService = new CampainService(this._authService);

        campaignService
            .Send({
                Language: this.state.selectedLanguage as string,
                Body: this.state.messageBody,
                Subject: this.state.messageTitle,
            })
            .then(() => {
                this.setState({ success: true });
            })
            .catch((e) => {
                this.setState({ error: 'An Error has occurred, please verify the data and try again' });
                console.log(e);
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    private ValidateForm(): boolean {
        const { messageBody, messageTitle, selectedLanguage }: IState = this.state;

        if (!messageTitle) {
            this.setState({ error: 'message Title is required' });
            return false;
        }
        if (!messageBody) {
            this.setState({ error: 'message Body is required' });
            return false;
        }
        if (!selectedLanguage) {
            this.setState({ error: 'language is required' });
            return false;
        }
        this.setState({ error: '' });
        return true;
    }

    private Logout() {
        this._authService.Logout();
        window.location.href = '/login';
    }

    private OnInputChange(e: any) {
        switch (e.target.name) {
            case 'messageTitle':
                this.setState({ messageTitle: e.target.value });
                break;
            case 'messageBody':
                this.setState({ messageBody: e.target.value });
                break;
            default:
                break;
        }
    }

    private OnLanguageSelect(value: string) {
        this.setState({ selectedLanguage: value });
        if (!this.state.eventStats || !this.state.eventStats[value] || this.state.eventStats[value] === 0) {
            this.setState({
                error: `${value.toUpperCase()} language has no attendees`,
            });
            this.setState({ isLoading: true });
        } else {
            this.setState({
                error: '',
            });
            this.setState({ isLoading: false });
        }
    }

    public render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }

        return (
            <Box mb={1}>
                <TopBar />
                <Box>
                    <Button onClick={this.Logout}>
                        <Typography variant="button" className={styles.logout}>
                            Logout
                        </Typography>
                    </Button>
                </Box>
                <Box alignItems="center" my={1} textAlign="center">
                    <Typography variant="h5" className={styles.title}>
                        Send Invite to Event Attendees
                    </Typography>
                </Box>
                <FormFieldDivider />
                <Box
                    mx="auto"
                    maxWidth={800}
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: null, sm: 'center' }}
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    px={{ xs: 2, sm: 4 }}
                >
                    {LANGUAGES.map((l) => {
                        return (
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent={{ xs: 'space-between', sm: null }}
                                width="auto"
                                my={{ xs: 1, sm: 2 }}
                                key={l.code}
                            >
                                <Box mx={1}>
                                    <Typography variant="body1">{l.name} : </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="body1">
                                        {_.contains(this.state.statsKeys, l.code) ? this.state.eventStats[l.code] : 0}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
                <FormFieldDivider />
                <Box mx="auto" maxWidth={800}>
                    {this.state.error && (
                        <Box py={0.5} px={1} mb={1} className={styles.errorBox} textAlign="center">
                            <Typography variant="subtitle1">{this.state.error} </Typography>
                        </Box>
                    )}
                    {this.state.success && (
                        <Box py={0.5} px={1} mb={1} className={styles.successBox} textAlign="center">
                            <Typography variant="subtitle1">Invitation sent successfully !</Typography>
                        </Box>
                    )}

                    <form onSubmit={this.OnSubmit}>
                        <Box px={{ xs: 2, sm: null }} className={styles.formBody}>
                            <FormField
                                isRequired={true}
                                id="language"
                                onChange={(value) => {
                                    this.OnLanguageSelect(value);
                                }}
                                inputType={'autocomplete'}
                                source={LANGUAGES.map((c) => {
                                    return { id: c.code, text: c.name };
                                })}
                                label="Language"
                                value={this.state.selectedLanguage}
                            />
                            <FormFieldDivider />
                            <FormField
                                onChange={this.OnInputChange}
                                focused={true}
                                isRequired={true}
                                id="messageTitle"
                                inputType={'text'}
                                label="Title"
                            />

                            <SunEditor
                                width="auto"
                                disable={this.state.isLoading}
                                height={150}
                                onChange={(content) => {
                                    this.setState({ messageBody: content });
                                }}
                                name="messageBody"
                            />
                        </Box>

                        <Box
                            width="100%"
                            justifyContent={{ xs: 'flex-center', sm: 'flex-end' }}
                            justifyItems="flex-end"
                            mt={2}
                            display="flex"
                        >
                            <Box
                                width={{ xs: '100%', sm: 250 }}
                                height={50}
                                className={styles.send}
                                alignItems="center"
                                display="flex"
                                mx={{ xs: 2, sm: null }}
                            >
                                <Button disabled={this.state.isLoading} fullWidth={true} type="submit">
                                    Send
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Box>
        );
    }
}
