/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { Redirect } from 'react-router-dom';
import PageBase from '../PageBase';

type IState = {
    redirect: string;
};
export default class Home extends PageBase {
    state: IState = {
        redirect: this._authService.IsAuthenticated ? '/event/manage' : '/login',
    };

    render() {
        return <Redirect to={this.state.redirect} />;
    }
}
