import { IConfig } from '../models/IConfig';

export const Config: IConfig = {
    ApiBaseUrl: process.env.API_BASE_URL ?? 'http://localhost:5050',
    IdApiBaseUrl: process.env.API_BASE_URL ?? 'http://localhost:5555',
    IdApiToken:
        process.env.ID_API_TOKEN ??
        'MndePXJULuUVkAFJpqjzroQrGFnDPSrT+xHJ3SgunnfTg6UTwJ8Hqsj2thnsGvuZqHcKiKLb6WmjLnYg8bAkHWGPrpx7RYtAAU3FfMVy7tGNHrFN3vjwzAwEmZbWY3WqK667XDFKJc2ia7whS7V6w2GthVidYzC9H',
    ApplicationName: process.env.APPLICATION_NAME ?? 'rpm-web-backend',
};
