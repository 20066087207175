import _ from 'underscore';
import { Utils } from '../../models/Utils';
export enum LocalStorageKeys {
    CurrentUser = 'currentuser',
    Token = 'token',
    Language = 'language',
}
export class LocalStorageService {
    public static Set<T>(key: string, value: T | null | undefined | string) {
        if (Utils.StringIsNullOrEmpty(key) || Utils.IsNull(value) || Utils.StringIsNullOrEmpty(value as string)) return;

        localStorage.setItem(key, _.isString(value) ? value : JSON.stringify(value));
    }

    public static Get<T>(key: string): T | null {
        if (Utils.IsNull(key)) return null;
        const result = localStorage.getItem(key);
        try {
            return result ? (JSON.parse(result) as T) : null;
        } catch (error) {
            return result as any;
        }
    }

    public static Delete(key: string) {
        if (Utils.IsNull(key)) return;
        localStorage.removeItem(key);
    }

    public static Clear() {
        localStorage.clear();
    }
}
