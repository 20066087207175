export const LANGUAGES = [
    {
        name: 'English',
        code: 'en',
    },
    {
        name: 'French',
        code: 'fr',
    },
    {
        name: 'Czech',
        code: 'cz',
    },
];
