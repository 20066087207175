import { Config } from '../config/Config';

export class ApiConfig {
    public static ServiceConfiguration: Rpm.Api.Client.Models.ServiceConfiguration = new Rpm.Api.Client.Models.ServiceConfiguration(
        Config.ApiBaseUrl,
        Config.ApplicationName,
    );
    public static IdServiceConfiguration: IdApi.Client.Models.ServiceConfiguration = new IdApi.Client.Models.ServiceConfiguration(
        Config.IdApiBaseUrl,
        Config.ApplicationName,
        Config.IdApiToken,
    );
}
