export class RmToken {
    constructor(token?: IdApi.Client.Models.IdOAuth2Token) {
        if (token) {
            this.RefreshToken = token.RefreshToken;
            this.RefreshTokenExpiration = token.RefreshTokenExpiration;
            this.TokenExpiration = token.TokenExpiration;
            this.Token = token.Token;
        }
    }
    RefreshToken: string;
    Token: string;
    TokenExpiration: Date;
    RefreshTokenExpiration: Date;
}
