import { ICampaignRequest } from '../../models/ICampaignRequest';

import { Utils } from '../../models/Utils';
import { ApiConfig } from '../ApiConfig';
import { AuthService } from '../auth/AuthService';

export class CampainService {
    constructor(private _authService: AuthService) {}
    public Send(request: ICampaignRequest): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const apiServiceConfig: Rpm.Api.Client.Models.ServiceConfiguration = ApiConfig.ServiceConfiguration;
                const tk = await this._authService.Token;

                apiServiceConfig.Token = tk ? tk.Token : '';

                if (Utils.StringIsNullOrEmpty(apiServiceConfig.Token)) {
                    throw new Error('A Token is required to make this call');
                }
                const attendeeService = new Rpm.Api.Client.Service.ManageEventAttendeeService(apiServiceConfig);
                await attendeeService.Invite(request);
                resolve(true);
            } catch (error) {
                reject(error);
            }
        });
    }
}
