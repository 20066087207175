import moment from 'moment';
import _ from 'underscore';
import { RmAuthenticationResult } from '../../models/RmAuthenticationResult';
import { RmToken } from '../../models/RmToken';
import { Utils } from '../../models/Utils';
import { ApiConfig } from '../ApiConfig';
import { Config } from '../../config/Config';

import { LocalStorageKeys, LocalStorageService } from '../localStorage/LocalStorageService';

export class AuthService {
    public Login(username: string, password: string): Promise<RmAuthenticationResult | null> {
        return this.Authenticate(username, password);
    }

    public Logout(): void {
        LocalStorageService.Delete(LocalStorageKeys.CurrentUser);
        LocalStorageService.Delete(LocalStorageKeys.Token);
    }

    private Authenticate(email: string, password: string): Promise<RmAuthenticationResult | null> {
        return new Promise<RmAuthenticationResult | null>(async (resolve, reject) => {
            const result: RmAuthenticationResult = new RmAuthenticationResult();

            try {
                const tokenResponse = await this.GetTokenInternal('password', email, password);
                if (!tokenResponse) {
                    resolve(null);
                    return;
                }

                ApiConfig.ServiceConfiguration.Token = tokenResponse.Token;
                const viewUserService = new Rpm.Api.Client.Service.ViewUserService(ApiConfig.ServiceConfiguration);
                const user = await viewUserService.GetOne(email);

                if (
                    !user ||
                    !_.contains(
                        [
                            Rpm.Api.Client.Models.UserRole.SystemAdmin,
                            Rpm.Api.Client.Models.UserRole.Admin,
                            Rpm.Api.Client.Models.UserRole.SuperAdmin,
                        ],
                        user.Role,
                    )
                ) {
                    result.Success = false;
                    result.Error = "You don't have access to the Manage application. Please contact your administrator";
                    resolve(result);
                    return;
                }

                result.Success = true;
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    }

    public get Token(): Promise<RmToken | null> {
        return new Promise<RmToken | null>(async (resolve, reject) => {
            try {
                const tk = LocalStorageService.Get<RmToken>(LocalStorageKeys.Token);
                if (!tk) {
                    resolve(null);
                    return;
                }
                if (this.TokenIsExpired(tk.TokenExpiration)) {
                    const tokenResponse = await this.GetTokenInternal('refreshtoken');
                    resolve(tokenResponse ? new RmToken(tokenResponse) : null);
                    return;
                }

                resolve(tk);
            } catch (error) {
                reject(error);
            }
        });
    }

    private GetTokenInternal(
        grantType: IdApi.Client.Models.TokenGrantTypes,
        email?: string,
        password?: string,
    ): Promise<IdApi.Client.Models.IdOAuth2Token | null> {
        return new Promise<IdApi.Client.Models.IdOAuth2Token | null>(async (resolve, reject) => {
            if (
                grantType === 'password' &&
                Utils.StringIsNullOrEmpty(email as string) &&
                Utils.StringIsNullOrEmpty(password as string)
            ) {
                resolve(null);
                return;
            }

            try {
                const serviceConfiguration = ApiConfig.ServiceConfiguration;
                const request = new IdApi.Client.Models.IdOAuth2TokenRequest();
                request.GrantType = grantType;
                request.Application = Config.ApplicationName;

                if (grantType === 'password') {
                    request.Email = email ? email.toLowerCase() : '';
                    request.Password = password ? password : '';
                } else {
                    const token = LocalStorageService.Get<RmToken>(LocalStorageKeys.Token);
                    if (!token) {
                        resolve(null);
                        return;
                    }
                    request.RefreshToken = token.RefreshToken;
                }

                const oAuth2Service = new IdApi.Client.Service.OAuthService(ApiConfig.IdServiceConfiguration);

                const tokenResult = await oAuth2Service.GetToken(request);
                if (tokenResult) {
                    const tokenObj: RmToken = new RmToken(tokenResult);
                    LocalStorageService.Set<RmToken>(LocalStorageKeys.Token, tokenObj);

                    if (!this.CurrentUser) {
                        serviceConfiguration.Token = tokenObj.Token;
                        const viewUserService = new Rpm.Api.Client.Service.ViewUserService(serviceConfiguration);
                        const user = await viewUserService.GetOne(tokenResult.User.Email);
                        LocalStorageService.Set<Rpm.Api.Client.Models.RmUser>(LocalStorageKeys.CurrentUser, user);
                    }
                }
                resolve(tokenResult);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    }

    public get IsAuthenticated(): boolean {
        const tk = LocalStorageService.Get<RmToken>(LocalStorageKeys.Token);
        return !Utils.IsNull(tk);
    }

    private TokenIsExpired(expirationDate: Date) {
        const now: moment.Moment = moment().utc();
        const diff: number = moment.duration(moment(expirationDate).diff(now)).asSeconds();
        /**120 second before it really expires, let get another token */
        return diff <= 120;
    }

    public get CurrentUser(): Rpm.Api.Client.Models.RmUser {
        return LocalStorageService.Get<Rpm.Api.Client.Models.RmUser>(
            LocalStorageKeys.CurrentUser,
        ) as Rpm.Api.Client.Models.RmUser;
    }
}
