import { AppBar, Box, MenuItem, Select, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { LANGUAGES } from '../config/languages';
import styles from '../styles/TopBar.module.scss';

type Props = {
    showLang?: boolean;
    onPageLanguageSwitch?: (language: any) => void;
};
const initialState = {
    pageLanguage: 'cz',
};

export class TopBar extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    state = initialState;
    public render() {
        return (
            <AppBar position="static" className={styles.appBar}>
                <Toolbar variant="dense" className={styles.topBar}>
                    <Box display="flex" alignItems="space-between" flexDirection="row" width="100%">
                        {this.props.showLang && (
                            <Box>
                                <Select
                                    onChange={(e: any) => {
                                        if (this.props.onPageLanguageSwitch) {
                                            this.setState({ pageLanguage: e.target.value });
                                            this.props.onPageLanguageSwitch(e.target.value);
                                        }
                                    }}
                                    id="page-lang"
                                    value={this.state.pageLanguage}
                                    disableUnderline={true}
                                >
                                    {LANGUAGES.map((s) => {
                                        return (
                                            <MenuItem key={s.code} value={s.code}>
                                                {s.code}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Box>
                        )}
                        {!this.props.showLang && <Box></Box>}
                        <Box flexGrow={1} display="flex" justifyContent="flex-end" alignItems="center">
                            <Typography variant="h5">Resurrection Power Ministries</Typography>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }
}
